// �ָ���
// =============================================================================

// ���ӱ߿���Ϊ�ָ��ߣ���ɫ�ķָ��ߣ�������ɫ����
.mdui-divider-top-light() {
  border-top: 1px solid @color-white-divider;
}
.mdui-divider-bottom-light() {
  border-bottom: 1px solid @color-white-divider;
}
.mdui-divider-left-light() {
  border-left: 1px solid @color-white-divider;
}
.mdui-divider-right-light() {
  border-right: 1px solid @color-white-divider;
}

// ���ӱ߿���Ϊ�ָ��ߣ���ɫ�ķָ��ߣ�����ǳɫ����
.mdui-divider-top-dark() {
  border-top: 1px solid @color-black-divider;
}
.mdui-divider-bottom-dark() {
  border-bottom: 1px solid @color-black-divider;
}
.mdui-divider-left-dark() {
  border-left: 1px solid @color-black-divider;
}
.mdui-divider-right-dark() {
  border-right: 1px solid @color-black-divider;
}
