/**
 * =============================================================================
 * ************   Fab ����������ť   ************
 * =============================================================================
 */

@import '../button/index';

@fab-size: 56px;
@fab-size-mini: 40px;

/* ����������ť */
.mdui-fab {
  width: @fab-size;
  min-width: @fab-size;
  height: @fab-size;
  margin: auto;
  padding: 0 !important;
  overflow: hidden;
  font-size: 24px;
  line-height: normal !important;
  border-radius: 50%;
  .mdui-shadow(6);

  &:hover{
    .mdui-shadow(8);
  }

  &:active {
    .mdui-shadow(12);
  }

  // ����������ť�е�ͼ��
  .mdui-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    margin-top: 16px;
    margin-left: 16px;
    line-height: 24px;
  }
}

/* mini �͸���������ť */
.mdui-fab-mini {
  width: @fab-size-mini;
  min-width: @fab-size-mini;
  height: @fab-size-mini;

  // mini �͸���������ť�е�ͼ��
  .mdui-icon {
    margin-top: 8px;
    margin-left: 8px;
  }
}

/* �̶������½ǵ� FAB ��ť */
.mdui-fab-fixed,
.mdui-fab-wrapper {
  position: fixed !important;

  /* �ֻ�ƽ���Ͼ�����Ļ���½� 16px */
  right: 16px;
  bottom: 16px;

  @media (min-width: @screen-md-min) {
    /* �����Ͼ�����Ļ���½� 24px */
    right: 24px;
    bottom: 24px;
  }
}

/* ���˵��ĸ���������ť */
.mdui-fab-wrapper {
  position: relative;
  z-index: @z-index-fab;
  width: @fab-size;
  height: @fab-size;
  padding-top: 8px;
  text-align: center;

  // �˵���ǰ
  &>.mdui-fab {
    .mdui-icon:not(.mdui-fab-opened) {
      opacity: 1;
      transition: all .2s @animation-curve-default;
      will-change: opacity, transform;
    }
    .mdui-icon.mdui-fab-opened {
      transform: rotate(225deg);
      opacity: 0;
      transition: all .2s @animation-curve-default;
      will-change: opacity, transform;
    }
  }

  // �˵��򿪺�
  &>.mdui-fab.mdui-fab-opened {
    .mdui-icon:not(.mdui-fab-opened) {
      transform: rotate(225deg);
      opacity: 0;
    }
    .mdui-icon.mdui-fab-opened {
      transform: rotate(360deg);
      opacity: 1;
    }
  }

  // mini ��ť��Ҫ��ֱ����
  &>.mdui-fab-mini {
    margin-top: (@fab-size - @fab-size-mini) / 2;
  }

  .mdui-fab-dial {
    position: absolute;
    right: 0;
    bottom: @fab-size + 8px;
    left: 0;
    height: 0;
    text-align: center;
    visibility: visible;

    .mdui-fab {
      margin: 8px 0;
      transform: scale(0);
      opacity: 0;
      transition: box-shadow .2s @animation-curve-fast-out-linear-in,
                  color .2s @animation-curve-default,
                  // fab
                  transform 150ms @animation-curve-default,
                  opacity 150ms @animation-curve-default;
    }

    &.mdui-fab-dial-show {
      .mdui-fab {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
}

/* ������ .mdui-fab-hide �Զ�������ʽ���ذ�ť */
.mdui-fab,
.mdui-fab-mini,
.mdui-fab-wrapper {
  transform: scale(1) translateZ(0);
  transition: all .2s @animation-curve-default,
              box-shadow .2s @animation-curve-fast-out-linear-in,
                // fab
              transform .2s;
  will-change: transform;

  &.mdui-fab-hide {
    transform: scale(0) translateZ(0);
  }
}
