/**
 * =============================================================================
 * ************   Icon ͼ��   ************
 * =============================================================================
 */

.mdui-icon,
.mdui-icon::before {
  color: inherit;
  font-weight: normal;
  font-size: 24px;  /* Preferred icon size */
  font-style: normal;
  line-height: 1;
  direction: ltr;
  letter-spacing: normal;
  white-space: nowrap;
  text-transform: none;
  vertical-align: middle;
  word-wrap: normal;
}

.mdui-icon {
  display: inline-block;
  text-align: center;
}

.mdui-icon::before {
  display: block !important;
  width: 24px;
  height: 24px;
}
