/**
 * =============================================================================
 * ************   Drawer ����������   ************
 * =============================================================================
 */

/* DOM ������������� transition */
body.mdui-loaded {
  transition: padding .3s @animation-curve-linear-out-slow-in;
}
body.mdui-loaded .mdui-drawer {
  transition: all .3s @animation-curve-linear-out-slow-in;
}

/* ��������㣬Ĭ�ϳ�������������� */
.mdui-drawer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: @z-index-drawer;
  box-sizing: border-box;

  // �ֻ��˿���Ϊ 100% - 56px�����Ϊ 280px
  /* stylelint-disable-next-line */
  width: calc(~"100% - 56px");
  max-width: @drawer-width-xs;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  white-space: nowrap;
  will-change: transform;
  -webkit-overflow-scrolling: touch;

  // ����ʱ����ʾ��Ӱ
  @media (max-width: @screen-sm-max) {
    &:not(.mdui-drawer-open) {
      box-shadow: none !important;
    }
  }

  // ƽ��˿���Ϊ 100% - 64px�����Ϊ 320px
  @media (min-width: @screen-sm-min) {
    /* stylelint-disable-next-line */
    width: calc(~"100% - 64px");
    max-width: @drawer-width-sm;
  }

  // ����˿���
  @media (min-width: @screen-md-min) {
    width: @drawer-width-md;
    max-width: none;

    // ����ʱ����ʾ��Ӱ
    &.mdui-drawer-close {
      box-shadow: none !important;
    }
  }
}

/* �������Ҳ�ĳ����� */
.mdui-drawer-right {
  right: 0;
  left: auto;
}

/* ƽ���ϵ���ʽ */
@media (max-width: @screen-sm-max) {
  .mdui-drawer {
    /* ʼ���б�������Ӱ */
    background-color: #fff;

    /* Ĭ������ */
    transform: translateX(-@drawer-width-sm - 10);
    .mdui-shadow(16);
  }

  .mdui-drawer-right {
    /* �Ҳ� drawer ҲĬ������ */
    transform: translateX(@drawer-width-sm + 10);
  }
}

/* �ֻ��ϵ���ʽ */
@media (max-width: @screen-xs-max) {
  .mdui-drawer {
    transform: translateX(-@drawer-width-xs - 10);
  }

  .mdui-drawer-right {
    transform: translateX(@drawer-width-xs + 10);
  }
}

/* ǿ�����س����� */
._drawer_close(@drawer-width) {
  .mdui-drawer-close {
    transform: translateX(-@drawer-width - 10);
    &.mdui-drawer-right {
      transform: translateX(@drawer-width + 10);
    }
  }
}

._drawer_close(@drawer-width-xs);

@media (min-width: @screen-sm-min) {
  ._drawer_close(@drawer-width-sm);
}

@media (min-width: @screen-md-min) {
  ._drawer_close(@drawer-width-md);
}

/* ǿ����ʾ������ */
.mdui-drawer-open {
  transform: translateX(0) !important;
}

/* PC �ϵ���ʽ */
@media (min-width: @screen-md-min) {
  /* ʹ��������Ԫ�ػ�� padding-left �� padding-right�����ⱻ����������סҳ�� */
  .mdui-drawer-body-left {
    padding-left: @drawer-width-md;
  }
  .mdui-drawer-body-right {
    padding-right: @drawer-width-md;
  }

  /* PC ��Ĭ�����ϱ߾� */
  .mdui-drawer {
    .mdui-appbar-with-toolbar & {
      top: @appbar-height-sm;
    }

    .mdui-appbar-with-tab & {
      top: 48px;
    }

    .mdui-appbar-with-tab-larger & {
      top: 72px;
    }

    .mdui-appbar-with-toolbar.mdui-appbar-with-tab & {
      top: @appbar-height-sm + 48px;
    }

    .mdui-appbar-with-toolbar.mdui-appbar-with-tab-larger & {
      top: @appbar-height-sm + 72px;
    }
  }
}

/* ����������͸��ʱ������Ӱ */
.mdui-drawer[class*="mdui-color-"]:not(.mdui-color-transparent) {
  .mdui-shadow(16);
}

/* ������ 100% �߶ȣ������ڵ��������� */
.mdui-drawer-full-height {
  top: 0 !important;
}


/**
 * =============================================================================
 * ************   Drawer dark   ************
 * =============================================================================
 */
.layout-theme({
  // �ֻ�ƽ���ϵ���ʽ
  @media (max-width: @screen-sm-max) {
    .mdui-drawer {
      background-color: @layout-dark-color-4;
    }
  }
});
