/**
 * =============================================================================
 * ************   Tab   ************
 * =============================================================================
 *
 * ���ֻ���ѡ�ʼ��ƽ�ֻ�ɹ�������ƽ�����ϵ��豸��Ĭ������룬����ѡ����ж��룬��ȫ���ȷ�
 */

/* ѡ���Ĭ�ϵ�ѡ�Ϊȫ�� */
.mdui-tab {
  position: relative;
  display: flex;
  min-height: 48px;
  max-height: 72px;
  margin: 0 auto;
  padding: 0;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;

  a {
    display: flex;
    // �ֻ���ʼ�յȷ�ѡ�
    flex: 1;

    // ʹͼ����ı���ֱ����
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    min-width: 72px;
    min-height: 48px;
    max-height: 72px;
    padding: 12px;
    overflow: hidden;
    color: inherit;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    text-overflow: ellipsis;
    outline: none;
    cursor: pointer;
    opacity: 0.7;
    user-select: none;

    .mdui-icon {
      opacity: 0.7;
    }

    // ƽ��������豸
    @media (min-width: @screen-sm-min) {

      // ƽ��/pc��ѡ������
      flex: none;
      min-width: 99px;
      max-width: 264px;

      // �ֻ��� padding 12px��ƽ��/pc�� padding 24px
      padding: 12px 24px;
    }

    @media (min-width: @screen-md-min) {
      min-width: 112px;
    }

    @media (min-width: @screen-lg-min) {
      min-width: 136px;
    }

    @media (min-width: @screen-xl-min) {
      min-width: 160px;
    }

    label {
      display: block;
      width: 100%;
      cursor: pointer;
    }

    .mdui-icon + label {
      margin-top: 8px;
    }

    &[disabled] {
      cursor: default;
      opacity: 0.38;

      label {
        cursor: default;
      }
    }
  }

  // ����״̬��ѡ��
  .mdui-tab-active {
    color: @color-default-500;
    opacity: 1;
    .mdui-icon {
      opacity: 1;
    }
  }
}

/* ѡ����� */
.mdui-tab-centered {
  @media (min-width: @screen-sm-min) {
    &::before {
      flex-grow: 1;
      content: ' ';
    }
    &::after {
      flex-grow: 1;
      content: ' ';
    }
    a {
      flex: none;
    }
  }
}

/* ѡ�ʼ��ȫ���ȷ� */
.mdui-tab-full-width {
  a {
    flex: 1;
    max-width: none;
  }
}


/* �ɺ��������ѡ� */
.mdui-tab-scrollable {
  padding-left: 56px;

  a {
    flex: none;
  }

  @media (max-width: @screen-xs-max) {
    padding-left: 60px;
  }

}

/* �»���ָʾ�� */
.mdui-tab-indicator {
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: @color-default-500;
  transition: all .35s @animation-curve-default;
  will-change: left, width;
}

/**
 * =============================================================================
 * ************   Tab ��ɫ   ************
 * =============================================================================
 */

& {
  .loop-primary-theme(@counter-color) when (@counter-color > 0) {
    .loop-primary-theme((@counter-color - 1));
    @colorName: extract(@globalPrimaryColors, @counter-color);
    @color: 'color-@{colorName}-500';

    .mdui-theme-primary-@{colorName} when not (@colorName = null) {
      .mdui-tab .mdui-tab-active {
        color: @@color;
      }

      .mdui-tab-indicator {
        background-color: @@color;
      }
    }

  }
  .loop-primary-theme(length(@globalPrimaryColors));
}

/* ������ɫ��ѡ� */
.mdui-tab[class*="mdui-color-"]:not(.mdui-color-white) {
  .mdui-tab-active {
    color: inherit;
  }
  .mdui-tab-indicator {
    background-color: @color-white-500;
  }

  // ������ɫ��ѡ���ʹ��ǳɫ������
  & {
    .mdui-scrollbar-beautify(dark);
  }
}
