/**
 * =============================================================================
 * ************   ������ʽ   ************
 * =============================================================================
 */
* {
  -webkit-tap-highlight-color: transparent;
}

body {
  color: @body-text-color;
  font-size: @screen-xs-font-size;
  font-family: @font-family-base;
  background-color: @body-background-color;
  @media (min-width: @screen-sm-min) {
    font-size: @screen-sm-font-size;
  }
  @media (min-width: @screen-md-min) {
    font-size: @screen-md-font-size;
  }

  // ��������ʽ
  * {
    .mdui-scrollbar-beautify(light);
  }
}

/* ������Ļ */
body.mdui-locked {
  overflow: hidden;
}

/* ���ֲ� */
.mdui-overlay {
  position: fixed;
  top: -5000px;
  right: -5000px;
  bottom: -5000px;
  left: -5000px;
  z-index: @z-index-overlay;
  background: rgba(0, 0, 0, .4);
  backface-visibility: hidden;
  visibility: hidden;
  opacity: 0;
  transition-duration: .3s;
  transition-property: opacity, visibility;
  will-change: opacity;
}

/* ��ʾ���ֲ� */
.mdui-overlay-show {
  visibility: visible;
  opacity: 1;
}

/* ȡ�� transition ����Ч�� */
.mdui-no-transition {
  transition-property: none !important;
}


/**
 * =============================================================================
 * ************   Global dark   ************
 * =============================================================================
 */
.layout-theme({
  color: #fff;
  background-color: @layout-dark-color-3;

  // ҹ��ģʽ��������ʽ
  * {
    .mdui-scrollbar-beautify(dark);
  }
});
