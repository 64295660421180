/**
 * =============================================================================
 * ************   Media ý�幤��   ************
 * =============================================================================
 */

/* ��ӦʽͼƬ����Ƶ */
.mdui-img-fluid,
.mdui-video-fluid {
  .mdui-img-fluid();
}

/* Բ��ͼƬ */
.mdui-img-rounded {
  border-radius: 2px;
}

/* Բ��ͼƬ */
.mdui-img-circle {
  border-radius: 50%;
}

// embed
.mdui-video-container {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
  }
}
