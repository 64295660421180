/**
 * =============================================================================
 * ************   Color ��ɫ   ************
 * =============================================================================
 */

@global-primary-color-degrees-length: length(@globalPrimaryColorDegrees);
@global-primary-colors-length: length(@globalPrimaryColors);
@global-accent-color-degrees-length: length(@globalAccentColorDegrees);
@global-accent-colors-length: length(@globalAccentColors);


/**
 * .mdui-theme-primary-[color] .mdui-color-theme
 * .mdui-theme-primary-[color] .mdui-color-theme-[degree]
 */

& {
  .loop-primary-theme(@counter-color) when (@counter-color > 0) {
    .loop-primary-theme((@counter-color - 1));

    @colorName: extract(@globalPrimaryColors, @counter-color);

    .mdui-theme-primary-@{colorName} when not (@colorName = null) {

      // .mdui-theme-primary-[color] .mdui-color-theme
      .mdui-color-theme {
        .mdui-color(@colorName) !important;
      }

      .loop-primary-degree-theme(@counter-degree) when (@counter-degree > 0) {
        .loop-primary-degree-theme((@counter-degree - 1));

        @degree: extract(@globalPrimaryColorDegrees, @counter-degree);

        // .mdui-theme-primary-[color] .mdui-color-theme-[degree]
        .mdui-color-theme-@{degree} when not (@degree = null) {
          .mdui-color(@colorName, @degree) !important;
        }
      }
      .loop-primary-degree-theme(@global-primary-color-degrees-length);
    }
  }
  .loop-primary-theme(@global-primary-colors-length);
}


/**
 * .mdui-theme-accent-[color] .mdui-color-theme-accent
 * .mdui-theme-accent-[color] .mdui-color-theme-[degree]
 */

& {
  .loop-accent-theme(@counter-color) when (@counter-color > 0) {
    .loop-accent-theme((@counter-color - 1));

    @colorName: extract(@globalAccentColors, @counter-color);

    .mdui-theme-accent-@{colorName} when not (@colorName = null) {

      // .mdui-theme-accent-[color] .mdui-color-theme-accent
      .mdui-color-theme-accent {
        .mdui-color(@colorName, a200) !important;
      }

      .loop-accent-degree-theme(@counter-degree) when (@counter-degree > 0) {
        .loop-accent-degree-theme((@counter-degree - 1));

        @degree: extract(@globalAccentColorDegrees, @counter-degree);

        // .mdui-theme-accent-[color] .mdui-color-theme-[degree]
        .mdui-color-theme-@{degree} when not (@degree = null) {
          .mdui-color(@colorName, @degree) !important;
        }
      }
      .loop-accent-degree-theme(@global-accent-color-degrees-length);
    }
  }
  .loop-accent-theme(@global-accent-colors-length);
}


/**
 * .mdui-color-[color]
 * .mdui-color-[color]-[degree]
 */

& {
  .loop-primary-theme(@counter-color) when (@counter-color > 0) {
    .loop-primary-theme((@counter-color - 1));

    @colorName: extract(@globalPrimaryColors, @counter-color);

    & {
      // .mdui-color-[color]
      .mdui-color-@{colorName} when not (@colorName = null) {
        .mdui-color(@colorName) !important;
      }

      .loop-primary-degree-theme(@counter-degree) when (@counter-degree > 0) {
        .loop-primary-degree-theme((@counter-degree - 1));

        @degree: extract(@globalPrimaryColorDegrees, @counter-degree);

        // .mdui-color-[color]-[degree]
        .mdui-color-@{colorName}-@{degree} when not (@degree = null) {
          .mdui-color(@colorName, @degree) !important;
        }
      }
      .loop-primary-degree-theme(@global-primary-color-degrees-length);
    }
  }
  .loop-primary-theme(@global-primary-colors-length);
}


/**
 * .mdui-color-[color]-accent
 * .mdui-color-[color]-[degree]
 */

& {
  .loop-accent-theme(@counter-color) when (@counter-color > 0) {
    .loop-accent-theme((@counter-color - 1));

    @colorName: extract(@globalAccentColors, @counter-color);

    &{
      // .mdui-color-[color]-accent
      .mdui-color-@{colorName}-accent when not (@colorName = null) {
        .mdui-color(@colorName, a200) !important;
      }

      .loop-accent-degree-theme(@counter-degree) when (@counter-degree > 0) {
        .loop-accent-degree-theme((@counter-degree - 1));

        @degree: extract(@globalAccentColorDegrees, @counter-degree);

        // .mdui-color-[color]-[degree]
        .mdui-color-@{colorName}-@{degree} when not (@degree = null) {
          .mdui-color(@colorName, @degree) !important;
        }
      }
      .loop-accent-degree-theme(@global-accent-color-degrees-length);
    }
  }
  .loop-accent-theme(@global-accent-colors-length);
}


/**
 * .mdui-color-black
 * .mdui-color-white
 * .mdui-color-transparent
 */

.mdui-color-black {
  color: @color-white-text !important;
  background-color: @color-black !important;
}

.mdui-color-white {
  color: @color-black-text !important;
  background-color: @color-white !important;
}

.mdui-color-transparent {
  background-color: @color-transparent !important;
}


/**
 * .mdui-theme-primary-[color] .mdui-text-color-theme
 * .mdui-theme-primary-[color] .mdui-text-color-theme-[degree]
 */

& {
  .loop-primary-theme(@counter-color) when (@counter-color > 0) {
    .loop-primary-theme((@counter-color - 1));

    @colorName: extract(@globalPrimaryColors, @counter-color);

    .mdui-theme-primary-@{colorName} when not (@colorName = null) {

      // .mdui-theme-primary-[color] .mdui-text-color-theme
      .mdui-text-color-theme {
        .mdui-text-color(@colorName) !important;
      }

      .loop-primary-degree-theme(@counter-degree) when (@counter-degree > 0) {
        .loop-primary-degree-theme((@counter-degree - 1));

        @degree: extract(@globalPrimaryColorDegrees, @counter-degree);

        // .mdui-theme-primary-[color] .mdui-text-color-theme-[degree]
        .mdui-text-color-theme-@{degree} when not (@degree = null) {
          .mdui-text-color(@colorName, @degree) !important;
        }
      }
      .loop-primary-degree-theme(@global-primary-color-degrees-length);
    }
  }
  .loop-primary-theme(@global-primary-colors-length);
}


/**
 * .mdui-theme-accent-[color] .mdui-text-color-theme-accent
 * .mdui-theme-accent-[color] .mdui-text-color-theme-[degree]
 */

& {
  .loop-accent-theme(@counter-color) when (@counter-color > 0) {
    .loop-accent-theme((@counter-color - 1));

    @colorName: extract(@globalAccentColors, @counter-color);

    .mdui-theme-accent-@{colorName} when not (@colorName = null) {

      // .mdui-theme-accent-[color] .mdui-text-color-theme-accent
      .mdui-text-color-theme-accent {
        .mdui-text-color(@colorName, a200) !important;
      }

      .loop-accent-degree-theme(@counter-degree) when (@counter-degree > 0) {
        .loop-accent-degree-theme((@counter-degree - 1));

        @degree: extract(@globalAccentColorDegrees, @counter-degree);

        // .mdui-theme-accent-[color] .mdui-text-color-theme-[degree]
        .mdui-text-color-theme-@{degree} when not (@degree = null) {
          .mdui-text-color(@colorName, @degree) !important;
        }
      }
      .loop-accent-degree-theme(@global-accent-color-degrees-length);
    }
  }
  .loop-accent-theme(@global-accent-colors-length);
}


/**
 * .mdui-text-color-[color]
 * .mdui-text-color-[color]-[degree]
 */

& {
  .loop-primary-theme(@counter-color) when (@counter-color > 0) {
    .loop-primary-theme((@counter-color - 1));

    @colorName: extract(@globalPrimaryColors, @counter-color);

    &{
      // .mdui-text-color-[color]
      .mdui-text-color-@{colorName} when not (@colorName = null) {
        .mdui-text-color(@colorName) !important;
      }

      .loop-primary-degree-theme(@counter-degree) when (@counter-degree > 0) {
        .loop-primary-degree-theme((@counter-degree - 1));

        @degree: extract(@globalPrimaryColorDegrees, @counter-degree);

        // .mdui-text-color-[color]-[degree]
        .mdui-text-color-@{colorName}-@{degree} when not (@degree = null) {
          .mdui-text-color(@colorName, @degree) !important;
        }
      }
      .loop-primary-degree-theme(@global-primary-color-degrees-length);
    }
  }
  .loop-primary-theme(@global-primary-colors-length);
}


/**
 * .mdui-text-color-[color]-accent
 * .mdui-text-color-[color]-[degree]
 */

& {
  .loop-accent-theme(@counter-color) when (@counter-color > 0) {
    .loop-accent-theme((@counter-color - 1));

    @colorName: extract(@globalAccentColors, @counter-color);

    &{
      // .mdui-text-color-[color]-accent
      .mdui-text-color-@{colorName}-accent when not (@colorName = null) {
        .mdui-text-color(@colorName, a200) !important;
      }

      .loop-accent-degree-theme(@counter-degree) when (@counter-degree > 0) {
        .loop-accent-degree-theme((@counter-degree - 1));

        @degree: extract(@globalAccentColorDegrees, @counter-degree);

        // .mdui-text-color-[color]-[degree]
        .mdui-text-color-@{colorName}-@{degree} when not (@degree = null) {
          .mdui-text-color(@colorName, @degree) !important;
        }
      }
      .loop-accent-degree-theme(@global-accent-color-degrees-length);
    }
  }
  .loop-accent-theme(@global-accent-colors-length);
}


/**
 * .mdui-text-color-black
 * .mdui-text-color-black-[]
 * .mdui-text-color-theme-[]
 */

.mdui-text-color-black {
  color: @color-black !important;
}
.mdui-text-color-black-text,
.mdui-text-color-theme-text {
  color: @color-black-text !important;
}
.mdui-text-color-black-secondary,
.mdui-text-color-theme-secondary {
  color: @color-black-secondary !important;
}
.mdui-text-color-black-disabled,
.mdui-text-color-theme-disabled {
  color: @color-black-disabled !important;
}
.mdui-text-color-black-divider,
.mdui-text-color-theme-divider {
  color: @color-black-divider !important;
}
.mdui-text-color-black-icon,
.mdui-text-color-theme-icon {
  color: @color-black-icon !important;
}
.mdui-text-color-black-icon-disabled,
.mdui-text-color-theme-icon-disabled {
  color: @color-black-icon-disabled !important;
}

/**
 * .mdui-text-color-white
 * .mdui-text-color-white-[]
 */
.mdui-text-color-white {
  color: @color-white !important;
}
.mdui-text-color-white-text {
  color: @color-white-text !important;
}
.mdui-text-color-white-secondary {
  color: @color-white-secondary !important;
}
.mdui-text-color-white-disabled {
  color: @color-white-disabled !important;
}
.mdui-text-color-white-divider {
  color: @color-white-divider !important;
}
.mdui-text-color-white-icon {
  color: @color-white-icon !important;
}
.mdui-text-color-white-icon-disabled {
  color: @color-white-icon-disabled !important;
}

/**
 * .mdui-text-color-theme-[] ��ɫ����
 */
.layout-theme({
  .mdui-text-color-theme-text {
    color: @color-white-text !important;
  }
  .mdui-text-color-theme-secondary {
    color: @color-white-secondary !important;
  }
  .mdui-text-color-theme-disabled {
    color: @color-white-disabled !important;
  }
  .mdui-text-color-theme-divider {
    color: @color-white-divider !important;
  }
  .mdui-text-color-theme-icon {
    color: @color-white-icon !important;
  }
  .mdui-text-color-theme-icon-disabled {
    color: @color-white-icon-disabled !important;
  }
});
