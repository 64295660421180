/**
 * =============================================================================
 * ************   Roboto ����   ************
 * =============================================================================
 */

@font-face {
  font-weight: 100;
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  src: local('Roboto Thin'), local('Roboto-Thin'),
    url('../fonts/roboto/Roboto-Thin.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-Thin.woff') format('woff');
}

@font-face {
  font-weight: 100;
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  src: local('Roboto ThinItalic'), local('Roboto-ThinItalic'),
    url('../fonts/roboto/Roboto-ThinItalic.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-ThinItalic.woff') format('woff');
}

@font-face {
  font-weight: 300;
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  src: local('Roboto Light'), local('Roboto-Light'),
    url('../fonts/roboto/Roboto-Light.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-Light.woff') format('woff');
}

@font-face {
  font-weight: 300;
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  src: local('Roboto LightItalic'), local('Roboto-LightItalic'),
    url('../fonts/roboto/Roboto-LightItalic.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-LightItalic.woff') format('woff');
}

@font-face {
  font-weight: 400;
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  src: local('Roboto Regular'), local('Roboto-Regular'),
    url('../fonts/roboto/Roboto-Regular.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-Regular.woff') format('woff');
}

@font-face {
  font-weight: 400;
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  src: local('Roboto RegularItalic'), local('Roboto-RegularItalic'),
    url('../fonts/roboto/Roboto-RegularItalic.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-RegularItalic.woff') format('woff');
}

@font-face {
  font-weight: 500;
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('../fonts/roboto/Roboto-Medium.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-Medium.woff') format('woff');
}

@font-face {
  font-weight: 500;
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  src: local('Roboto MediumItalic'), local('Roboto-MediumItalic'),
    url('../fonts/roboto/Roboto-MediumItalic.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-MediumItalic.woff') format('woff');
}

@font-face {
  font-weight: 700;
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('../fonts/roboto/Roboto-Bold.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-Bold.woff') format('woff');
}

@font-face {
  font-weight: 700;
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  src: local('Roboto BoldItalic'), local('Roboto-BoldItalic'),
    url('../fonts/roboto/Roboto-BoldItalic.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-BoldItalic.woff') format('woff');
}

@font-face {
  font-weight: 900;
  font-family: 'Roboto';
  font-style: normal;
  font-display: swap;
  src: local('Roboto Black'), local('Roboto-Black'),
    url('../fonts/roboto/Roboto-Black.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-Black.woff') format('woff');
}

@font-face {
  font-weight: 900;
  font-family: 'Roboto';
  font-style: italic;
  font-display: swap;
  src: local('Roboto BlackItalic'), local('Roboto-BlackItalic'),
    url('../fonts/roboto/Roboto-BlackItalic.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-BlackItalic.woff') format('woff');
}
