/**
 * =============================================================================
 * ************   Headroom   ************
 * =============================================================================
 */

 .mdui-headroom {
  transition: all .3s @animation-curve-default !important;
}

/* �̶��ڶ���ʱ */
.mdui-headroom-pinned-top {
  transform: translate3d(0, 0, 0) !important;
}

/* �ڶ�������ʱ */
.mdui-headroom-unpinned-top {
  transform: translate3d(0, -100%, 0) !important;
  .mdui-shadow(0) !important;
}

/* �̶��ڵײ�ʱ */
.mdui-headroom-pinned-down {
  transform: translate3d(0, 0, 0) !important;
}

/* �ڵײ�����ʱ */
.mdui-headroom-unpinned-down {
  transform: translate3d(0, 100%, 0) !important;
  .mdui-shadow(0) !important;
}

/* �̶�Ӧ�����еĹ����� */
.mdui-headroom-pinned-toolbar {
  transform: translate3d(0, 0, 0) !important;
}

/* ����Ӧ�����еĹ����� */
.mdui-headroom-unpinned-toolbar {
  transform: translate3d(0, -@appbar-height-xs-portrait, 0) !important;

  // ƽ�������
  @media (min-width: @screen-sm-min) {
    transform: translate3d(0, -@appbar-height-sm, 0) !important;
  }

  // �ֻ�����
  @media (orientation: landscape) and (max-width: @screen-xs-max-landscape) {
    transform: translate3d(0, -@appbar-height-xs-landscape, 0) !important;
  }
}
