/**
 * =============================================================================
 * ************   Shadow ��Ӱ   ************
 * =============================================================================
 */

._md_shadow(@shadow) when (@shadow > -1) {
  ._md_shadow(@shadow - 1);

  .mdui-shadow-@{shadow} {
    .mdui-shadow(@shadow) !important;
  }
}

._md_shadow(24);

/* �������ʱ������Ӱ */
.mdui-hoverable {
  .mdui-hoverable(8);
}
