/**
 * =============================================================================
 * ************   Helper ������   ************
 * =============================================================================
 */

/**
 * =================== �߾�
 */
._spacing_margin_mixin(@spacing) when (@spacing > -1) {
  ._spacing_margin_mixin(@spacing - 1);

  .mdui-m-a-@{spacing} {
    .mdui-m-a(@spacing) !important;
  }
  .mdui-m-t-@{spacing} {
    .mdui-m-t(@spacing) !important;
  }
  .mdui-m-r-@{spacing} {
    .mdui-m-r(@spacing) !important;
  }
  .mdui-m-b-@{spacing} {
    .mdui-m-b(@spacing) !important;
  }
  .mdui-m-l-@{spacing} {
    .mdui-m-l(@spacing) !important;
  }
  .mdui-m-x-@{spacing} {
    .mdui-m-x(@spacing) !important;
  }
  .mdui-m-y-@{spacing} {
    .mdui-m-y(@spacing) !important;
  }

  .mdui-p-a-@{spacing} {
    .mdui-p-a(@spacing) !important;
  }
  .mdui-p-t-@{spacing} {
    .mdui-p-t(@spacing) !important;
  }
  .mdui-p-r-@{spacing} {
    .mdui-p-r(@spacing) !important;
  }
  .mdui-p-b-@{spacing} {
    .mdui-p-b(@spacing) !important;
  }
  .mdui-p-l-@{spacing} {
    .mdui-p-l(@spacing) !important;
  }
  .mdui-p-x-@{spacing} {
    .mdui-p-x(@spacing) !important;
  }
  .mdui-p-y-@{spacing} {
    .mdui-p-y(@spacing) !important;
  }
}

._spacing_margin_mixin(5);


/**
 * ======================== ���ٸ���
 */

/* ���󸡶� */
.mdui-float-left {
  float: left !important;
}

/* ���Ҹ��� */
.mdui-float-right {
  float: right !important;
}

/**
 * ========================= ˮƽ����
 */

/* ˮƽ���� */
.mdui-center {
  .mdui-center() !important;
}

/**
 * ========================= ��ֱ����
 */

/* ��ֱ���� */
.mdui-valign {
  .mdui-valign() !important;
}

/**
 * ========================= �ı����뷽ʽ
 */

/* �ı������ */
.mdui-text-left {
  text-align: left !important;
}

/* �ı����ж��� */
.mdui-text-center {
  text-align: center !important;
}

/* �ı����Ҷ��� */
.mdui-text-right {
  text-align: right !important;
}

/**
 * ========================= �ı���Сдת��
 */

/* �ı�תΪСд */
.mdui-text-lowercase {
  text-transform: lowercase !important;
}

/* �ı�תΪ��д */
.mdui-text-uppercase {
  text-transform: uppercase !important;
}

/* �ı�תΪ���ʵ�����ĸ��д */
.mdui-text-capitalize {
  text-transform: capitalize !important;
}

/**
 * ======================== �ı��ض�
 */

/* �ı��ض� */
.mdui-text-truncate {
  .mdui-text-truncate();
}

/**
 * ========================= �������
 */

/* ������� */
.mdui-clearfix {
  .mdui-clearfix();
}

/**
 * ========================= ��������
 */

/* ����Ԫ�� */
.mdui-hidden,
[hidden] {
  display: none !important;
}

/* ʹԪ�ز��ɼ� */
.mdui-invisible {
  visibility: hidden;
}

/* Responsive utilities ��Ӧʽ����
   ========================================================================== */

/* ���ض���Ļ���ر������� */
@media (max-width: @screen-xs-max) {
  .mdui-hidden-xs {
    display: none !important;
  }
}
@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
  .mdui-hidden-sm {
    display: none !important;
  }
}
@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
  .mdui-hidden-md {
    display: none !important;
  }
}
@media (min-width: @screen-lg-min) and (max-width: @screen-lg-max) {
  .mdui-hidden-lg {
    display: none !important;
  }
}
@media (min-width: @screen-xl-min) {
  .mdui-hidden-xl {
    display: none !important;
  }
}

/* �ڱ��ض���ĻС���豸������ */
@media (max-width: @screen-xs-max) {
  .mdui-hidden-xs-down {
    display: none !important;
  }
}
@media (max-width: @screen-sm-max) {
  .mdui-hidden-sm-down {
    display: none !important;
  }
}
@media (max-width: @screen-md-max) {
  .mdui-hidden-md-down {
    display: none !important;
  }
}
@media (max-width: @screen-lg-max) {
  .mdui-hidden-lg-down {
    display: none !important;
  }
}
.mdui-hidden-xl-down {
  display: none !important;
}

/* �ڱ��ض���Ļ����豸������ */
.mdui-hidden-xs-up {
  display: none !important;
}
@media (min-width: @screen-sm-min) {
  .mdui-hidden-sm-up {
    display: none !important;
  }
}
@media (min-width: @screen-md-min) {
  .mdui-hidden-md-up {
    display: none !important;
  }
}
@media (min-width: @screen-lg-min) {
  .mdui-hidden-lg-up {
    display: none !important;
  }
}
@media (min-width: @screen-xl-min) {
  .mdui-hidden-xl-up {
    display: none !important;
  }
}
