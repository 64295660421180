/**
 * =============================================================================
 * ************   Radio ��ѡ��   ************
 * =============================================================================
 */

.mdui-radio {
  position: relative;
  display: inline-block;
  height: 36px;
  padding-left: 36px;
  line-height: 36px;
  cursor: pointer;
  user-select: none;

  // ����ϵͳ input ��ǩ�����Զ����ͼ�����
  input {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
  }
}

/* ͼ����Ȧ */
.mdui-radio-icon {
  position: absolute;
  top: 9px;
  left: 0;
  display: inline-block;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  vertical-align: middle;
  border: 2px solid @color-black-icon;
  border-radius: 18px;
  transition: all .3s @animation-curve-default,
              box-shadow .14s @animation-curve-default;

  // ͼ���ڲ�ԲȦ
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 14px;
    height: 14px;
    background-color: @color-default-a200;
    border-radius: 14px;
    transform: scale(0);
    opacity: 0;
    transition: all .3s @animation-curve-default;
    content: ' ';
  }
}

/* ѡ��״̬��ͼ�� */
.mdui-radio input[type="radio"]:checked + .mdui-radio-icon {
  border-color: @color-default-a200;

  &::before {
    transform: scale(0.68);
    opacity: 1;
  }
}

/* ����״̬��ͼ�� */
.mdui-radio input[type="radio"]:disabled + .mdui-radio-icon {
  border-color: @color-black-icon-disabled !important;
}

/* ������ѡ��״̬��ͼ�� */
.mdui-radio input[type="radio"]:disabled:checked + .mdui-radio-icon {
  &::before {
    background-color: @color-black-icon-disabled !important;
  }
}

/* δѡ�С�����ʱ ���µ���Ӱ */
.mdui-radio:active input[type="radio"],
.mdui-radio input[type="radio"]:focus {
  & + .mdui-radio-icon {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0.1);
  }
}

/* ��ѡ��ʱ���µ���Ӱ */
.mdui-radio:active input[type="radio"],
.mdui-radio input[type="radio"]:focus {
  &:checked:not(:disabled) + .mdui-radio-icon {
    box-shadow: 0 0 0 15px rgba(red(@color-default-a200), green(@color-default-a200), blue(@color-default-a200), 0.16);
  }
}

/**
 * =============================================================================
 * ************   Radio ǿ��ɫ   ************
 * =============================================================================
 */

& {
  .loop-accent-theme(@counter-color) when (@counter-color > 0) {
    .loop-accent-theme((@counter-color - 1));
    @colorName: extract(@globalAccentColors, @counter-color);

    @color: 'color-@{colorName}-a200';

    .mdui-theme-accent-@{colorName} when not (@colorName = null) {

      // ѡ��״̬��ͼ��
      .mdui-radio input[type="radio"]:checked + .mdui-radio-icon {
        border-color: @@color;

        &::before {
          background-color: @@color;
        }
      }

      // ��ѡ��ʱ���µ���Ӱ
      .mdui-radio:active input[type="radio"],
      .mdui-radio input[type="radio"]:focus {
        &:checked:not(:disabled) + .mdui-radio-icon {
          box-shadow: 0 0 0 15px rgba(red(@@color), green(@@color), blue(@@color), 0.16);
        }
      }

    }
  }
  .loop-accent-theme(length(@globalAccentColors));
}


/**
 * =============================================================================
 * ************   Radio dark   ************
 * =============================================================================
 */
.layout-theme({
  // ͼ����Ȧ
  .mdui-radio-icon {
    border-color: rgba(255, 255, 255, 0.7);
  }

  // ����״̬
  .mdui-radio input[type="radio"]:disabled + .mdui-radio-icon {
    border-color: @color-white-icon-disabled !important;
  }

  // ������ѡ��״̬
  .mdui-radio input[type="radio"]:disabled:checked + .mdui-radio-icon {
    &::before {
      background-color: @color-white-icon-disabled !important;
    }
  }

  // δѡ�л����ʱ ���µ���Ӱ
  .mdui-radio:active input[type="radio"],
  .mdui-radio input[type="radio"]:focus {
    & + .mdui-radio-icon {
      box-shadow: 0 0 0 15px rgba(255, 255, 255, 0.1);
    }
  }
});
