/**
 * =============================================================================
 * ************   Bottom navigation �ײ�������   ************
 * =============================================================================
 */

@import '../headroom/index';

.mdui-bottom-nav {
  position: relative;
  display: flex;
  height: 56px;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;

  @media (min-width: @screen-sm-min) {
    &::before {
      flex-grow: 1;
      content: ' ';
    }
    &::after {
      flex-grow: 1;
      content: ' ';
    }
  }

  a {
    display: flex;
    flex: 1;

    // ʹͼ����ı���ֱ����
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    max-width: none;
    padding: 8px 12px 10px 12px;
    overflow: hidden;
    color: inherit;
    font-size: 12px;
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis;
    cursor: pointer;
    opacity: 0.7;
    transition: all .2s @animation-curve-default ;
    user-select: none;
    will-change: padding;

    @media (min-width: @screen-sm-min) {
      max-width: 144px;
    }

    .mdui-icon {
      opacity: 0.7;
    }

    label {;
      display: block;
      width: 100%;
      cursor: pointer;
      transition: transform .2s @animation-curve-default ;
      will-change: font-size;
    }

    .mdui-icon + label {
      margin-top: 6px;
    }

    // ����״̬�ĵ�����
    &.mdui-bottom-nav-active {
      color: @color-default-500;
      font-size: 14px;
      opacity: 1;

      .mdui-icon {
        opacity: 1;
      }
    }
  }
}

/* ֻ�ڼ���ʱ��ʾ�ı� */
&.mdui-bottom-nav-text-auto {
  a {
    min-width: 32px;
    padding-right: 0;
    padding-left: 0;
    @media (min-width: @screen-sm-min) {
      max-width: 156px;
    }
    .mdui-icon {
      padding-top: 16px;
      transition: padding-top .2s @animation-curve-default;
      will-change: padding-top;
    }
    label {
      transform: scale(0);
    }

    &.mdui-bottom-nav-active {
      padding-right: 18px;
      padding-left: 18px;
      .mdui-icon {
        padding-top: 0;
      }
      label {
        transform: scale(1);
      }
    }
  }
}

/* �̶���ҳ��ײ� */
.mdui-bottom-nav-fixed {
  .mdui-bottom-nav {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
  }

  padding-bottom: 56px;
}

/**
 * =============================================================================
 * ************   Bottom Nav ��ɫ   ************
 * =============================================================================
 */

& {
  .loop-primary-theme(@counter-color) when (@counter-color > 0) {
    .loop-primary-theme((@counter-color - 1));
    @colorName: extract(@globalPrimaryColors, @counter-color);
    @color: 'color-@{colorName}-500';

    .mdui-theme-primary-@{colorName} when not (@colorName = null) {
      .mdui-bottom-nav a.mdui-bottom-nav-active {
        color: @@color;
      }
    }

  }
  .loop-primary-theme(length(@globalPrimaryColors));
}

/* ������ɫ�ĵײ������� */
.mdui-bottom-nav[class*="mdui-color-"] {
  .mdui-bottom-nav-active {
    color: inherit !important;
  }
}
