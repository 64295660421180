/**
 * =============================================================================
 * ************   Select ����ѡ��   ************
 * =============================================================================
 */

.mdui-select {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  height: 36px;
  padding-right: 24px;
  padding-left: 0;
  font-size: 16px;
  font-family: @font-family-base;
  vertical-align: middle;
  //background-image: data-uri('image/svg+xml', './components/select/svg/arrow_down_dark.svg');
  // https://www.zhangxinxu.com/sp/svgo/ CSS ת��
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpath d='M-.003 2.5l5 5 5-5h-10z' opacity='.54'/%3E%3C/svg%3E");

  // ��ͷ
  background-repeat: no-repeat;
  background-position: right center;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  outline: none;
  cursor: pointer;
  transition-timing-function: @animation-curve-default;
  transition-duration: .2s;
  transition-property: background-color, box-shadow, background-position-x;
  appearance: none;
  user-select: none;
  will-change: background-color, box-shadow, background-position-x;

  &.mdui-select-open {
    border-bottom: none;
  }
}

.mdui-select-position-top {
  //background-image: data-uri('image/svg+xml', './components/select/svg/arrow_up_dark.svg');
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpath d='M10.003 7.5l-5-5-5 5h10z' opacity='.54'/%3E%3C/svg%3E");
}

/* �ײ��Ͷ����˵�����ʽ */
.mdui-select-open {
  &.mdui-select-position-top,
  &.mdui-select-position-bottom {
    z-index: 99999;
    background-color: #fff;
    /* stylelint-disable-next-line */
    background-position-x: calc(~'100% - 12px');
    border-radius: 2px;
  }

  &.mdui-select-position-top {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 2px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0 10px 10px -3px rgba(0,0,0,.2), 0 0 14px 1px rgba(0,0,0,.14), 0 -7px 24px 2px rgba(0,0,0,.12);

    .mdui-select-menu {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;

      // �����·�����Ӱ
      box-shadow: 0 -4px 4px -2px rgba(0,0,0,.06), 8px 0 8px -4px rgba(0,0,0,.12), -8px 0 8px -4px rgba(0,0,0,.12)
    }
  }

  &.mdui-select-position-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    .mdui-shadow(8);

    .mdui-select-menu {
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      // �����Ϸ���Ӱ
      box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 9px 9px 1px rgba(0,0,0,.14), 0 8px 8px 2px rgba(0,0,0,.06);
    }
  }
}

/* ��ǰѡ�����ı� */
.mdui-select-selected {
  position: absolute;
  top: 50%;
  right: 24px;
  left: 0;
  display: block;
  transform: translateY(-50%);
  transition: left .2s @animation-curve-linear-out-slow-in;
  .mdui-text-truncate();

  .mdui-select-open & {
    right: 32px;
    left: 16px;
  }

  .mdui-select-open.mdui-select-position-auto & {
    visibility: hidden;
  }
}

/* �˵� */
.mdui-select-menu {
  position: relative;
  z-index: 99999;
  box-sizing: border-box;
  height: 36px;
  margin: 0 -24px 0 0;

  // �ڼ������ʱ���ѹ����������ȥ
  overflow-y: scroll;
  color: @color-black-text;
  background-color: #fff;
  border-radius: 2px;
  transform: scale(1, 0);
  visibility: hidden;
  cursor: default;
  opacity: 0;
  transition-timing-function: @animation-curve-linear-out-slow-in;
  transition-duration: .2s;
  transition-property: transform, opacity, visibility;
  will-change: transform, opacity, visibility;
  -webkit-overflow-scrolling: touch;
  .mdui-shadow(8);

  // ��״̬�Ĳ˵�
  .mdui-select-open & {
    overflow-y: hidden;
    transform: scale(1, 1);
    visibility: visible;
    opacity: 1;
  }

  // �ر�״̬�Ĳ˵�
  .mdui-select-closing & {
    overflow-y: hidden;
    box-shadow: none;
    transform: scale(1, 1);
    visibility: visible;
    opacity: 0;
  }
}

/* �˵��� */
.mdui-select-menu-item {
  height: 48px;
  padding: 0 16px;
  line-height: 48px;
  cursor: pointer;
  .mdui-text-truncate();

  &:hover {
    background-color: @color-grey-200;
  }

  &:first-child {
    margin-top: 8px;
  }

  &:last-child {
    margin-bottom: 8px;
  }

  // ������
  &[disabled] {
    color: @color-black-disabled !important;
    cursor: default;

    &:hover {
      background-color: inherit !important;
    }
  }

  // ��ѡ����
  &[selected] {
    color: @color-default-a200;
  }
}


/* ԭ�� select ��� */
select.mdui-select {
  background-color: transparent;

  option {
    color: rgba(0, 0, 0, 0.87);
  }

  // �Ƴ� IE ��������ť
  &::-ms-expand {
    display: none;
  }
}

/* ԭ����ѡ select ��� */
select.mdui-select[multiple] {
  height: auto;
  padding: 0;
  font-size: 15px;
  background-color: #FFF;
  background-image: none;
  border: 1px solid rgba(0, 0, 0, 0.38);
  cursor: default;

  optgroup {
    margin: 8px 0 0 0;
    padding: 0 0 0 16px;
    color: rgba(0, 0, 0, 0.38);

    &:last-child {
      margin-bottom: 8px;
    }

    &:not(:first-child) {
      padding-top: 8px;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
  }

  option {
    display: flex;
    align-items: center;
    height: 32px;
    margin: 0 0 0 -16px;
    padding: 0 16px;
    color: rgba(0, 0, 0, 0.87);

    &:first-child {
      margin-top: 8px;
    }

    &:last-child {
      margin-bottom: 8px;
    }
  }
}

/**
 * =============================================================================
 * ************   Select ǿ��ɫ   ************
 * =============================================================================
 */

& {
  .loop-accent-theme(@counter-color) when (@counter-color > 0) {
    .loop-accent-theme((@counter-color - 1));
    @colorName: extract(@globalAccentColors, @counter-color);

    .mdui-theme-accent-@{colorName} when not (@colorName = null) {

      .mdui-select-menu-item {
        // �˵�����ѡ����
        &[selected] {
          .mdui-text-color(@colorName);
        }
      }

    }
  }
  .loop-accent-theme(length(@globalAccentColors));
}


/**
 * =============================================================================
 * ************   Select dark   ************
 * =============================================================================
 */
.layout-theme({
  .mdui-select {
    //background-image: data-uri('image/svg+xml', './components/select/svg/arrow_down_light.svg');
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpath d='M-.003 2.5l5 5 5-5h-10z' fill='%23FFF'/%3E%3C/svg%3E");
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  }

  .mdui-select-position-top {
    //background-image: data-uri('image/svg+xml', './components/select/svg/arrow_up_light.svg');
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpath d='M10.003 7.5l-5-5-5 5h10z' fill='%23FFF'/%3E%3C/svg%3E");
  }

  .mdui-select-open {
    &.mdui-select-position-top,
    &.mdui-select-position-bottom {
      background-color: @layout-dark-color-4;
    }

    &.mdui-select-position-top {
      border-top: 1px solid rgba(255, 255, 255, 0.12);
    }

    &.mdui-select-position-bottom {
      border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    }
  }

  .mdui-select-menu {
    color: #fff;
    background-color: @layout-dark-color-4;
  }

  .mdui-select-menu-item {
    &:hover {
      background-color: @color-grey-700;
    }

    &[disabled] {
      color: @color-white-disabled !important;
    }
  }

  // ԭ�� select ���
  select.mdui-select {
    color: #FFF;
    background-color: @layout-dark-color-3;

    option {
      color: #FFF;
      background-color: @layout-dark-color-3;
    }
  }

  // ԭ����ѡ select ���
  select.mdui-select[multiple] {
    border: 1px solid rgba(255, 255, 255, 0.5);

    optgroup {
      color: rgba(255, 255, 255, 0.5);

      &:not(:first-child) {
        border-top: 1px solid rgba(255, 255, 255, 0.12);
      }
    }
  }
});
