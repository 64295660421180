/**
 * =============================================================================
 * ************   Expansion panel ����չ���   ************
 * =============================================================================
 */

/* ����չ��� */
.mdui-panel {
  box-sizing: border-box;
  width: 100%;
}

/* ����� */
.mdui-panel-item {
  color: @color-black-text;
  background-color: #fff;
  transition: margin .3s @animation-curve-default;
  will-change: margin;
  .mdui-divider-bottom-dark();
  .mdui-shadow(2);

  &:last-child {
    border-bottom: none;
  }
}

/* ���ͷ�� */
.mdui-panel-item-header {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  padding: 0 24px;
  font-size: 15px;
  cursor: pointer;
  transition: all .3s @animation-curve-default;
  will-change: height, background-color;
  .mdui-text-truncate();

  &:active {
    background-color: @color-grey-200;
  }
}

/* �������� */
.mdui-panel-item-title {
  box-sizing: border-box;
  width: 36%;
  min-width: 36%;
  padding-right: 16px;
  font-weight: 500;
  .mdui-text-truncate();
}

/* ��������� */
.mdui-panel-item-summary {
  flex-grow: 1;
  box-sizing: border-box;
  padding-right: 16px;
  color: @color-black-secondary;
  .mdui-text-truncate();
}

/* չ�������ͼ�� */
.mdui-panel-item-arrow {
  position: absolute;
  top: 12px;
  right: 24px;
  color: @color-black-icon;
  transform: rotate(0);
  transition: all .3s @animation-curve-default;
  user-select: none;
  will-change: transform, top;
}

.mdui-panel-item-summary + .mdui-panel-item-arrow {
  position: relative;
  top: 0 !important;
  right: 0;
}

/* ��������� */
.mdui-panel-item-body {
  height: 0;
  padding: 0 24px;
  overflow: hidden;
  transition: height .3s @animation-curve-default;
  will-change: height;
  .mdui-clearfix();

  &::after {
    height: 16px;
  }
}

/* ���������� */
.mdui-panel-item-actions {
  display: block;
  width: 100%;
  margin: 16px -24px 0 -24px;
  padding: 16px 24px 0 24px;
  text-align: right;
  .mdui-divider-top-dark();

  .mdui-btn {
    margin-left: 8px;

    &:first-child {
      margin-left: 0;
    }
  }
}

/* ��״̬������� */
.mdui-panel-item-open {
  height: auto;
  margin-top: 16px;
  margin-bottom: 16px;

  // ��״̬�����ͷ������
  &>.mdui-panel-item-header {
    height: 64px;

    .mdui-panel-item-arrow {
      top: 20px;
      transform: rotate(180deg);
    }
  }

  &>.mdui-panel-item-body {
    height: auto;
  }
}

/* ʹ��������û�б߾� */
.mdui-panel-gapless {
  .mdui-shadow(2);

  .mdui-panel-item {
    .mdui-shadow(0);
  }

  .mdui-panel-item-open {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* ������� */
.mdui-panel-popout {
  .mdui-panel-item-open {
    margin-right: -16px;
    margin-left: -16px;
  }
}


/**
 * =============================================================================
 * ************   Expansion panel dark   ************
 * =============================================================================
 */
.layout-theme({
  .mdui-panel-item {
    color: #fff;
    background-color: @layout-dark-color-3;
    .mdui-divider-bottom-light();

    &:last-child {
      border-bottom: none;
    }
  }
  .mdui-panel-item-header {
    &:active {
      background-color: @color-grey-800;
    }
  }
  .mdui-panel-item-summary {
    color: @color-white-secondary;
  }

  /* stylelint-disable-next-line */
  .mdui-panel-item-arrow {
    color: @color-white-icon;
  }
  .mdui-panel-item-actions {
    .mdui-divider-top-light();
  }
});
