/**
 * =============================================================================
 * ************   Typography ��ʽ�Ű�   ************
 * =============================================================================
 */

/* .mdui-typo-display-4 */
.mdui-typo-display-4,
.mdui-typo-display-4-opacity {
  .mdui-typo-display-4();
}
.mdui-typo-display-4-opacity {
  ._mdui-typo-display-4-opacity();
}

/* .mdui-typo-display-3 */
.mdui-typo-display-3,
.mdui-typo-display-3-opacity {
  .mdui-typo-display-3();
}
.mdui-typo-display-3-opacity {
  ._mdui-typo-display-3-opacity();
}

/* .mdui-typo-display-2 */
.mdui-typo-display-2,
.mdui-typo-display-2-opacity {
  .mdui-typo-display-2();
}
.mdui-typo-display-2-opacity {
  ._mdui-typo-display-2-opacity();
}

/* .mdui-typo-display-1 */
.mdui-typo-display-1,
.mdui-typo-display-1-opacity {
  .mdui-typo-display-1();
}
.mdui-typo-display-1-opacity {
  ._mdui-typo-display-1-opacity();
}

/* .mdui-typo-headline */
.mdui-typo-headline,
.mdui-typo-headline-opacity {
  .mdui-typo-headline();
}
.mdui-typo-headline-opacity {
  ._mdui-typo-headline-opacity();
}

/* .mdui-typo-title */
.mdui-typo-title,
.mdui-typo-title-opacity {
  .mdui-typo-title();
}
.mdui-typo-title-opacity {
  ._mdui-typo-title-opacity();
}

.mdui-typo-subheading,
.mdui-typo-subheading-opacity {
  .mdui-typo-subheading();
}
.mdui-typo-subheading-opacity {
  ._mdui-typo-subheading-opacity();
}

.mdui-typo-body-2,
.mdui-typo-body-2-opacity {
  .mdui-typo-body-2();
}
.mdui-typo-body-2-opacity {
  ._mdui-typo-body-2-opacity();
}

.mdui-typo-body-1,
.mdui-typo-body-1-opacity {
  .mdui-typo-body-1();
}
.mdui-typo-body-1-opacity {
  ._mdui-typo-body-1-opacity();
}

.mdui-typo-caption,
.mdui-typo-caption-opacity {
  .mdui-typo-caption();
}
.mdui-typo-caption-opacity {
  ._mdui-typo-caption-opacity();
}
